
<div class="card mb-3">
  <div class="card-header bg-score d-flex align-items-center justify-content-between">
    <div>MELD</div>
    <div>{{ meldScore | number:'1.2-2' }}</div>
  </div>
  <div class="card-body">

    <form [formGroup]="meldForm.Group" novalidate>

      <tt-form-input [parameters]="meldForm.GetInputElement('inr')"></tt-form-input>

      <div class="row mt-3 mt-md-0">
        <div class="col-md-4">
          <tt-form-input [parameters]="meldForm.GetInputElement('bilirubin')"></tt-form-input>
        </div>
        <div class="col-md-4">
          <tt-form-dropdown [parameters]="bilirubinDropdown" (onSelect)="onBilirubinMesure($event)"></tt-form-dropdown>
        </div>
      </div>

      <div class="row mt-3 mt-md-0">
        <div class="col-md-4">
          <tt-form-input [parameters]="meldForm.GetInputElement('creatinine')"></tt-form-input>
        </div>
        <div class="col-md-4">
          <tt-form-dropdown [parameters]="creatinineDropdown" (onSelect)="onCreatininenMesure($event)"></tt-form-dropdown>
        </div>
      </div>

      <tt-form-general-error [submitForm]="meldForm"></tt-form-general-error>

    </form>

    <p class="card-text font-italic">Kamath R et al. Hepatol 2001</p>
  </div>
</div>