import { Component, OnInit, HostListener, ViewChild, ElementRef, NgZone, EventEmitter, Output, Input, SimpleChanges, OnChanges, SimpleChange } from '@angular/core';
import { UnityService } from '../../modules/unity-service/unity.service';
import { SubmitForm, ISubmitFormElement, ISubmitFormInputErrors } from '../../../../common/modules/utilities/submitform/submitform.class';
import { FormBuilder, Validators } from '@angular/forms';
import { ITTFormDropdowParameters } from '../../../../common/modules/ngmodules/shared_editor/tt-form-dropdown/tt-form-dropdown.component';
import { ITTFormNameData } from '../../../../common/modules/ngmodules/shared_editor/shared.interfaces';
import { IScoresUpdate, IMeld } from '../../utility/IScoresUpdate';


export enum MesureUnit {
  MicroMolPerLitre = 'microMolPerLitre',
  MiligramPerDeciLitre = 'mgPerDeciLitre',
}

@Component({
  selector: 'app-meld',
  templateUrl: './meld.component.html',
  styleUrls: ['./meld.component.scss'],
})
export class MeldComponent implements OnInit, OnChanges {
  @Output() onScoresUpdated = new EventEmitter();
  @Input() scores: IScoresUpdate;

  meldForm: SubmitForm;
  loading = false;

  bilirubinDropdown: ITTFormDropdowParameters;
  creatinineDropdown: ITTFormDropdowParameters;
  
  _meld: IMeld = {
    _INR: 0,
    _Bilirubin_mgDL: 0,
    _Creatinine_mgDL: 0,
    _IsAlcoholic: false
  };
  
  meldScore: number;

  bilirubinMesure: MesureUnit = MesureUnit.MicroMolPerLitre;
  creatinineMesure: MesureUnit = MesureUnit.MicroMolPerLitre;

  constructor(
    private fb: FormBuilder,
    private unityService: UnityService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changeMap) {
    console.log(changeMap);
    if ('scores' in changeMap) {
      const changes: SimpleChange = changeMap['scores'];
      if (changes.currentValue) {
        this._meld = (<IScoresUpdate>changes.currentValue).meld;

        // Reset the form
        this.createForm();

        // Recalculate
        this._recalculate(false);
        this.updateUI();
      }
    }
  }

  updateUI() {

    this.meldForm.SetValue('inr', +(this._meld._INR).toFixed(2), false);

    let bilimgDL = this._meld._Bilirubin_mgDL;
    switch (this.bilirubinMesure) {
      case MesureUnit.MicroMolPerLitre:
        bilimgDL = +(bilimgDL * 17.1).toFixed(2);
        break;
    }

    this.meldForm.SetValue('bilirubin', +(bilimgDL).toFixed(2), false);

    let creatDL = this._meld._Creatinine_mgDL;
    switch (this.creatinineMesure) {
      case MesureUnit.MicroMolPerLitre:
        creatDL = +(creatDL * 88.4).toFixed(2);
        break;
    }

    this.meldForm.SetValue('creatinine', +(creatDL).toFixed(2), false);
  }

  private _recalculate(fromUser: boolean) {

    this.meldScore = (3.78 * Math.log(this._meld._Bilirubin_mgDL < 1 ? 1 : this._meld._Bilirubin_mgDL)) +
      (11.2 * Math.log(this._meld._INR < 1 ? 1 : this._meld._INR)) +
      (9.57 * Math.log(this._meld._Creatinine_mgDL < 1 ? 1 : this._meld._Creatinine_mgDL)) +
      6.43 * (this._meld._IsAlcoholic ? 0 : 1);

    if (fromUser) {
      this.onScoresUpdated.emit(this.scores);
    };

  }

  createForm() {
    this.meldForm = new SubmitForm(
      this.fb,
      [
        <ISubmitFormElement>{
          name: 'inr',
          value: 1,
          validators: [Validators.required],
          type: 'number',
          title: 'INR',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        },
        <ISubmitFormElement>{
          name: 'bilirubin',
          value: 1,
          validators: [Validators.required],
          type: 'number',
          title: 'Bilirubin',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        },
        <ISubmitFormElement>{
          name: 'creatinine',
          value: '',
          validators: [Validators.required],
          type: 'number',
          title: 'Creatinine',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        }
      ],
      // Submit callback
      data => {
        this.loading = true;
        // TODO: Do something
      },

      // Success callback
      () => {
        
      },

      // Fail callback
      err => {
        
      },

      // Changes callback
      (key, value) => {        
        switch (key) {
          case 'inr': 
            this._meld._INR = parseFloat(value);
            break;
          case 'bilirubin':
            this.setBilirubin(parseFloat(value));
            break;
          case 'creatinine':
            this.setCreatinine(parseFloat(value));
            break;
        }
        this._recalculate(true);
      }
    );

    const mesureChoices = [
      <ITTFormNameData>{
        name: 'µmol/L',
        data: MesureUnit.MicroMolPerLitre
      },
      <ITTFormNameData>{
        name: 'mg/dL',
        data: MesureUnit.MiligramPerDeciLitre
      }
    ];


    this.bilirubinDropdown = <ITTFormDropdowParameters>{
      choices: mesureChoices,
      selectedIndex : 0,
      input_title: 'Mesure',
      title: null,
      hasButton: false,
      help: ''
    };

    this.creatinineDropdown = <ITTFormDropdowParameters>{
      choices: mesureChoices,
      selectedIndex : 0,
      input_title: 'Mesure',
      title: null,
      hasButton: false,
      help: ''
    };
  }

  private setBilirubin(value: number) {
    switch (this.bilirubinMesure) {
      case MesureUnit.MicroMolPerLitre:
        this._meld._Bilirubin_mgDL = +(value / 17.1).toFixed(2);
        break;
      case MesureUnit.MiligramPerDeciLitre:
        this._meld._Bilirubin_mgDL = value;
        break;
    }
    // Clamp to max 1
    this._meld._Bilirubin_mgDL = Math.max(this._meld._Bilirubin_mgDL, 1);
  }

  private setCreatinine(value: number) {
    switch (this.creatinineMesure) {
      case MesureUnit.MicroMolPerLitre:
        this._meld._Creatinine_mgDL = +(value / 88.4).toFixed(2);
        break;
      case MesureUnit.MiligramPerDeciLitre:
        this._meld._Creatinine_mgDL = value;
        break;
    }

    // Clamp to max 1
    this._meld._Creatinine_mgDL = Math.max(this._meld._Creatinine_mgDL, 1);
  }

  onBilirubinMesure(mesure) {
    this.bilirubinMesure = mesure.data;
    this.setBilirubin(this.meldForm.GetValue('bilirubin'));
    this._recalculate(true);
    this.updateUI();
  }

  onCreatininenMesure(mesure) {
    this.creatinineMesure = mesure.data;
    this.setCreatinine(this.meldForm.GetValue('creatinine'));
    this._recalculate(true);
    this.updateUI();
  }

}
