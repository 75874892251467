import { IProject } from '../../common/modules/interfaces/config/IProject';
import { API_VERSION, API_SOCKET_TIMEOUT, APP_PROJECT_CONFIG } from '../../common/environment/project.global';

export const project: IProject = {
  api: {
    version: API_VERSION,
    socket: {
      use: false,
      timeout: API_SOCKET_TIMEOUT
    }
  },

  app: APP_PROJECT_CONFIG,

  base: '/account',

  login: {
    role: null,                   // Unspecified for account
    defaultRole: 'consumer'       // Default is 'consumer' on login
  },

};
