import { Component, OnInit, HostListener, ViewChild, ElementRef, NgZone, Output, EventEmitter } from '@angular/core';
import { UnityService } from '../../modules/unity-service/unity.service';


@Component({
  selector: 'app-unity',
  templateUrl: './unity-app.component.html',
  styleUrls: ['./unity-app.component.scss'],
})
export class UnityAppComponent implements OnInit {

  @ViewChild('game') game: ElementRef;
  @ViewChild('splash') splash: ElementRef;
  @ViewChild('progress') progress: ElementRef;

  @Output() onScoresUpdate = new EventEmitter();
  @Output() onStartRebuild = new EventEmitter();
  @Output() onStopRebuild = new EventEmitter();
  @Output() onShowScores = new EventEmitter();

  composeEmail = false;

  constructor(private _unityService: UnityService, private zone: NgZone) {
  }

  ngOnInit() {
    this._unityService.load('gamecontainer', (progress) => { this.onProgress(progress); } );
  }

  onProgress(progress: number) {
    this.progress.nativeElement.style = 'width: ' + (progress * 100) + '%;';

    if (progress >= 1) {
      this.splash.nativeElement.style = 'display: none;';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    const canvas = document.getElementsByTagName('canvas')[0];

    // Lookup the size the browser is displaying the canvas.
    const displayWidth  = this.game.nativeElement.clientWidth;
    const displayHeight = this.game.nativeElement.clientHeight;

    // Check if the canvas is not the same size.
    if (canvas.width  !== displayWidth ||
        canvas.height !== displayHeight) {

      // console.log("Resetting to: " + displayWidth + 'x' + displayHeight);
      // Make the canvas the same size
      canvas.width  = displayWidth;
      canvas.height = displayHeight;
    }

  }

  @HostListener('window:message', ['$event'])
  onReceivedMessage(event) {
    if (!event.data) {
      return;
    }

    let data = null;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      // console.error(e, event.data);
    }

    if (!data) {
      return;
    }

    if (!data.unityEvent || !(data.unityEvent === 'from-unity')) {
      return;
    }

    this.zone.run(() => {
      if (data.type === 'compose-email') {
        this.composeEmail = true;
      } else if (data.type === 'update-calculators') {
        this.onScoresUpdate.emit(data);
      } else if (data.type === 'start-rebuild') {
        this.onStartRebuild.emit();
      } else if (data.type === 'stop-rebuild') {
        this.onStopRebuild.emit();
      } else if (data.type === 'show-calculators') {
        this.onShowScores.emit();
      }
    });
  }

  closeEmail() {
    this.composeEmail = false;
  }

}
