import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISubmitFormElement } from '../../../utilities/submitform/submitform.class';


export interface ITTFormInputButton {
  text: string;
  alwaysEnabled: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tt-form-input',
  templateUrl: './tt-form-input.component.html'
})
export class TTFormInputComponent {
  @Input() parameters: ISubmitFormElement ;
  @Input() button: ITTFormInputButton;
  @Output() onButtonClick = new EventEmitter();

  onClick() {
    this.onButtonClick.emit(this.parameters._submitForm.GetValue(this.parameters.name));
  }

  disableButton() {
    if (this.button.alwaysEnabled) {
      return false;
    }
    return (!this.parameters._submitForm.HasValue(this.parameters.name));
  }
}

// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
