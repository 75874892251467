var SubmitForm = /** @class */ (function () {
    function SubmitForm(fb, elements, asyncCall, onSuccess, onError, onChange, errors) {
        if (errors === void 0) { errors = []; }
        var _this = this;
        this.Enabled = true;
        this.Submitting = false;
        this.Error = '';
        this.HasGeneralError = false;
        this._elements = null;
        this._asyncCall = null;
        this._onSuccess = null;
        this._onError = null;
        this._onChange = null;
        this._generalErrors = [];
        this._fb = fb;
        this._elements = elements;
        this._asyncCall = asyncCall;
        this._onSuccess = onSuccess;
        this._onError = onError;
        this._onChange = onChange;
        this._groupParams = {};
        this._elementMap = {};
        this._outputPathMap = {};
        this._changeMap = {};
        // Setup input fields if there are any
        for (var i = 0; i < this._elements.length; ++i) {
            var element = this._elements[i];
            if (!element.type) {
                element.type = 'text';
            }
            if (!element.errors) {
                element.errors = [];
            }
            if (!element.autocomplete) {
                element.autocomplete = 'off';
            }
            if (!element.output) {
                element.output = [];
            }
            if (!element.output_name) {
                element.output_name = element.name;
            }
            // Setup output path
            element._outputPath = '';
            for (var j = 0; j < element.output.length; ++j) {
                element._outputPath += element.output[j] + '.';
            }
            element._outputPath += element.output_name;
            this._outputPathMap[element._outputPath] = element.name;
            // Setup the form group parameters
            this._groupParams[element.name] = [{ value: element.value, disabled: element.disabled }, element.validators];
            // Set reference to this
            element._submitForm = this;
            // Save to map
            this._elementMap[element.name] = element;
        }
        // Setup form group
        this.Group = this._fb.group(this._groupParams);
        // Convert all inputs to form inputs
        /*for (let k = 0; k < this._elements.length; ++k)
        {
          var element: ISubmitFormElement = this._elements[k];
        }*/
        // Setup listener for changes
        this.Group.valueChanges.forEach(function (next) {
            if (_this.Group.dirty) {
                for (var k = 0; k < _this._elements.length; ++k) {
                    var element = _this._elements[k];
                    var comp = _this.Group.get(element.name);
                    if (comp.dirty && comp.valid) {
                        // Update the internal change map
                        _this.updateChangeMap(element, comp);
                        // Signal external
                        if (_this._onChange) {
                            _this._onChange(element.name, comp.value);
                        }
                    }
                }
            }
        });
        // Setup general errors
        if (errors) {
            this._generalErrors = errors;
        }
    }
    SubmitForm.prototype.updateChangeMap = function (element, component) {
        this.AddToChangeMap(element.output, element.output_name, component.value);
    };
    SubmitForm.prototype.removeFromChangeMap = function (element, component) {
        this.RemoveFromChangeMap(element.output, element.output_name, component.value);
    };
    Object.defineProperty(SubmitForm.prototype, "ChangeMap", {
        get: function () {
            return this._changeMap;
        },
        enumerable: true,
        configurable: true
    });
    SubmitForm.prototype.AddToChangeMap = function (path, outName, value) {
        var current = this._changeMap;
        for (var i = 0; i < path.length; ++i) {
            var name_1 = path[i];
            if (!(name_1 in current)) {
                current[name_1] = {};
            }
            current = current[name_1];
        }
        current[outName] = value;
    };
    SubmitForm.prototype.RemoveFromChangeMap = function (path, outName, value) {
        var current = this._changeMap;
        for (var i = 0; i < path.length; ++i) {
            var name_2 = path[i];
            if (!(name_2 in current)) {
                current[name_2] = {};
            }
            current = current[name_2];
        }
        if (outName in current) {
            delete current[outName];
        }
    };
    SubmitForm.prototype.GetInputElement = function (key) {
        return this._elementMap[key];
    };
    SubmitForm.prototype.SetEnabled = function (enabled) {
        this.Enabled = enabled;
        this.Submitting = !enabled;
        if (!this.Enabled) {
            this.Group.disable();
        }
        else {
            this.Group.enable();
        }
        // Go through and disable all already disabled
        for (var i = 0; i < this._elements.length; ++i) {
            var element = this._elements[i];
            if (element.disabled) {
                var comp = this.Group.get(element.name);
                comp.disable();
            }
        }
    };
    SubmitForm.prototype.GetValue = function (key) {
        var comp = this.Group.get(key);
        if (comp) {
            return comp.value;
        }
        else {
            return null;
        }
    };
    SubmitForm.prototype.SetValue = function (key, value, updateChangeMap) {
        if (updateChangeMap === void 0) { updateChangeMap = true; }
        var comp = this.Group.get(key);
        if (comp) {
            comp.setValue(value);
            if (updateChangeMap) {
                comp.markAsTouched();
            }
            if (updateChangeMap) {
                this.updateChangeMap(this._elementMap[key], comp);
            }
        }
    };
    SubmitForm.prototype.RemoveValue = function (key, updateChangeMap) {
        if (updateChangeMap === void 0) { updateChangeMap = true; }
        var comp = this.Group.get(key);
        if (comp) {
            comp.reset();
            if (updateChangeMap) {
                comp.markAsTouched();
            }
            if (updateChangeMap) {
                this.removeFromChangeMap(this._elementMap[key], comp);
            }
        }
    };
    SubmitForm.prototype.HasValue = function (key) {
        var comp = this.Group.get(key);
        if (comp) {
            return comp.dirty && comp.valid;
        }
        return false;
    };
    SubmitForm.prototype.Submit = function () {
        var _this = this;
        this.HasGeneralError = false;
        this.Error = null;
        for (var i = 0; i < this._elements.length; ++i) {
            var element = this._elements[i];
            var comp = this.Group.get(element.name);
            // Mark the controls as touched
            comp.markAsTouched();
        }
        // Cancel if not valid
        if (this.Group.invalid) {
            return;
        }
        this.SetEnabled(false);
        this._asyncCall(this._changeMap)
            .then(function (result) {
            _this.SetEnabled(true);
            if (_this._onSuccess) {
                _this._onSuccess(result);
            }
        }, function (err) {
            _this.OverrideError(err);
        });
    };
    SubmitForm.prototype.OverrideError = function (err) {
        var _this = this;
        this.SetEnabled(true);
        var hasErr = false;
        if (err.data && err.data.length > 0) {
            err.data.forEach(function (errData) {
                hasErr = _this._processData(errData) || hasErr;
            });
        }
        if (!hasErr) {
            var errMessage = err.message;
            if (err.data && err.data.length > 0) {
                for (var i = 0; i < err.data.length; ++i) {
                    errMessage = this._getGeneralError(err.data[i]) || errMessage;
                    break;
                }
            }
            this.SetGeneralError(errMessage);
        }
        if (this._onError) {
            this._onError(err);
        }
    };
    SubmitForm.prototype._getGeneralError = function (err) {
        for (var i = 0; i < this._generalErrors.length; ++i) {
            var gErr = this._generalErrors[i];
            if (err.path === gErr.field) {
                if (err.keys[gErr.code]) {
                    return gErr.message;
                }
            }
        }
        return null;
    };
    SubmitForm.prototype._processData = function (data, prefix) {
        var _this = this;
        if (prefix === void 0) { prefix = null; }
        var hasErr = false;
        var path = data.path;
        if (prefix) {
            path = prefix + '.' + path;
        }
        var comp = this.Group.get(path);
        if (!comp && path in this._outputPathMap) {
            comp = this.Group.get(this._outputPathMap[path]);
        }
        if (comp) {
            comp.setErrors(data.keys);
            hasErr = true;
        }
        if (data.children) {
            data.children.forEach(function (childData) {
                hasErr = _this._processData(childData, path) || hasErr;
            });
        }
        return hasErr;
    };
    SubmitForm.prototype.SetGeneralError = function (message) {
        this.HasGeneralError = true;
        if (message) {
            this.Error = message;
        }
        else {
            this.Error = null;
        }
    };
    SubmitForm.prototype.ClearGeneralError = function () {
        this.HasGeneralError = false;
        this.Error = null;
    };
    SubmitForm.prototype.Reset = function () {
        this.Group.reset();
        this._changeMap = {};
    };
    SubmitForm.prototype.FeedbackHidden = function (item) {
        var comp = this.Group.get(item);
        return (comp.valid || comp.pristine) && !comp.touched;
    };
    SubmitForm.prototype.ErrorHidden = function (item, error) {
        if (this.Group.get(item).errors) {
            // console.log(this.Group.get(item).errors);              /// ------------------ UNCOMMENT TO SEE WHY ERROR MESSAGES NOT SHOWING ----------------- ///
            if (error in this.Group.get(item).errors) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
    SubmitForm.prototype.GeneralErrorHidden = function () {
        return !this.HasGeneralError;
    };
    SubmitForm.prototype.GeneralErrorHasMessage = function () {
        return this.Error != null;
    };
    Object.defineProperty(SubmitForm.prototype, "Valid", {
        get: function () {
            return this.Group.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubmitForm.prototype, "Dirty", {
        get: function () {
            return this.Group.dirty;
        },
        enumerable: true,
        configurable: true
    });
    return SubmitForm;
}());
export { SubmitForm };
