import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { ITTFormNameData } from '../shared.interfaces';


export interface ITTFormDropdowParameters {
  choices: ITTFormNameData[];
  selectedIndex: number;
  input_title: string;
  title: string;
  hasButton: boolean;
  button_title: string;
  help: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tt-form-dropdown',
  templateUrl: './tt-form-dropdown.component.html',
  styleUrls: ['./tt-form-dropdown.component.scss']

})
export class TTFormDropdownComponent implements OnInit, OnChanges {
  @Input() parameters: ITTFormDropdowParameters ;
  @Output() onSelect = new EventEmitter();
  @Output() onClick = new EventEmitter();

  index = 0;

  get selected(): string {
    return this.parameters.choices[this.index].name;
  }

  get hasData(): boolean {
    if (this.parameters) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    if (this.parameters) {
      this.index = this.parameters.selectedIndex;
    }
  }

  ngOnChanges(changeMap) {
    if ('parameters' in changeMap) {
      const change: SimpleChange = changeMap.parameters;

      if (change.currentValue) {
        this.index = this.parameters.selectedIndex;
      }
    }
  }

  onSelected(nameValue: ITTFormNameData) {

    this.index = this.parameters.choices.indexOf(nameValue);
    this.onSelect.emit(nameValue);
  }

  onClicked() {
    this.onClick.emit(this.parameters.choices[this.index]);
  }
}
