
<div class="container bg-blue-bayer">    
  <div class="row">
    <div class="col p-0">
      
      <app-unity (onScoresUpdate)="onScoresUpdate($event)" (onStartRebuild)="onStartRebuild()" (onStopRebuild)="onStopRebuild()" (onShowScores)="onShowScores()"></app-unity>
      
    </div>
  </div>
</div>

<div class="container-fluid bg-white pt-3 pb-4">
  <div class="container">
    <div #scoreElement class="row">
      <div class="col pl-0 pr-0">
        <h2>Scores</h2>  
        
        <app-meld [scores]="scores" (onScoresUpdated)="onScoresUpdatedByUser($event)" class="mb-3"></app-meld>
        <app-childpugh [scores]="scores" (onScoresUpdated)="onScoresUpdatedByUser($event)" class="mb-3"></app-childpugh>
        <app-duvoux [scores]="scores" (onScoresUpdated)="onScoresUpdatedByUser($event)" class="mb-3"></app-duvoux>
        <tt-wait [show]="busy"></tt-wait>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-darker-bayer fg-white  pt-3 pb-3">
  <div class="container">
    <div #legalElement class="row">
      <div class="col">
        <app-legal></app-legal>
      </div>
    </div>
  </div>
</div>
