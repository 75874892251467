import { Inject, Injectable } from '@angular/core';

import { IConfig } from '../interfaces/config/IConfig';
import { IProject } from '../interfaces/config/IProject';

@Injectable()
export class Config {

  private _config: IConfig;
  private _project: IProject;

  constructor(
    @Inject('CONFIG') config,
    @Inject('PROJECT') project
  ) {
    this._config = config;
    this._project = project;
  }

  get Environment(): IConfig {
    return this._config;
  }

  get Project(): IProject {
    return this._project;
  }


  /**
   * The fully qualified path for the API: https://domain/subpath/version
   */
  public get ApiFullPath(): string {
    return this.Environment.api.origin + this.Environment.api.subpath + this.Project.api.version;
  }

  /// ---------- APP ------------ ///
  public get AppFullPath(): string {
    return this.Environment.app.origin + this.Environment.app.subpath;
  }

  public get AppFullPathProject(): string {
    return this.AppFullPath + this.Project.base;
  }

  public get AppAccountFullPath(): string {
    return this.AppFullPath + this.Project.app.account.url;
  }

  public get AppFullPath_Login(): string {
    return this.AppAccountFullPath + this.Project.app.account.login;
  }

  public get AppFullPath_Login_Role(): string {
    return this.AppFullPath_Login + '/' + this.Project.login.role;
  }

  public get AppFullPath_Signup(): string {
    return this.AppAccountFullPath + this.Project.app.account.signup;
  }

  public get AppFullPath_Signup_Role(): string {
    return this.AppFullPath_Signup + '/' + this.Project.login.role;
  }

  public get AppFullPath_AnonAuth(): string {
    return this.AppAccountFullPath + this.Project.app.account.anonAuth;
  }

  public get AppFullPath_AnonAuth_Role(): string {
    return this.AppFullPath_AnonAuth + '/' + this.Project.login.role;
  }


}
