
<div class="embed-responsive embed-responsive-16by9">
  
  <div #game class="embed-responsive-item" id="gamecontainer">
    
  </div>
  
  

  <div #splash class="loader-full embed-responsive-item">
    <h5>Chargement...</h5>
    <div class="loader-back">       
      <div #progress class="loader-fill"></div>
    </div>
  </div>

  
  <div *ngIf="composeEmail" class="email-full embed-responsive-item">
      <div class="container-fluid">
        <app-compose-email (onDone)="closeEmail()"></app-compose-email>        
      </div>
  </div>
  
</div>
