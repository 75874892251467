/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./compose.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../common/modules/ngmodules/shared_editor/tt-form-input/tt-form-input.component.ngfactory";
import * as i4 from "../../../../common/modules/ngmodules/shared_editor/tt-form-input/tt-form-input.component";
import * as i5 from "../../../../common/modules/ngmodules/shared_editor/tt-form-textarea/tt-form-textarea.component.ngfactory";
import * as i6 from "../../../../common/modules/ngmodules/shared_editor/tt-form-textarea/tt-form-textarea.component";
import * as i7 from "../../../../common/modules/ngmodules/shared_editor/tt-form-general-error/tt-form-general-error.component.ngfactory";
import * as i8 from "../../../../common/modules/ngmodules/shared_editor/tt-form-general-error/tt-form-general-error.component";
import * as i9 from "./compose.component";
import * as i10 from "../../modules/unity-service/unity.service";
var styles_ComposeEmailComponent = [i0.styles];
var RenderType_ComposeEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComposeEmailComponent, data: {} });
export { RenderType_ComposeEmailComponent as RenderType_ComposeEmailComponent };
export function View_ComposeEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "row emailComposer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Envoyer un email"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(8, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "tt-form-input", [], null, null, null, i3.View_TTFormInputComponent_0, i3.RenderType_TTFormInputComponent)), i1.ɵdid(12, 49152, null, 0, i4.TTFormInputComponent, [], { parameters: [0, "parameters"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "tt-form-input", [], null, null, null, i3.View_TTFormInputComponent_0, i3.RenderType_TTFormInputComponent)), i1.ɵdid(14, 49152, null, 0, i4.TTFormInputComponent, [], { parameters: [0, "parameters"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "tt-form-textarea", [], null, null, null, i5.View_TTFormTextAreaComponent_0, i5.RenderType_TTFormTextAreaComponent)), i1.ɵdid(16, 49152, null, 0, i6.TTFormTextAreaComponent, [], { parameters: [0, "parameters"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "tt-form-general-error", [], null, null, null, i7.View_TTFormGeneralErrorComponent_0, i7.RenderType_TTFormGeneralErrorComponent)), i1.ɵdid(18, 49152, null, 0, i8.TTFormGeneralErrorComponent, [], { submitForm: [0, "submitForm"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-success tt-btn-large-round tt-btn-pointer"], ["id", "signup"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Envoyez ! "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-danger tt-btn-large-round tt-btn-pointer ml-3"], ["id", "signup"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Annuler "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.emailForm.Group; _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.emailForm.GetInputElement("email"); _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.emailForm.GetInputElement("objet"); _ck(_v, 14, 0, currVal_9); var currVal_10 = _co.emailForm.GetInputElement("message"); _ck(_v, 16, 0, currVal_10); var currVal_11 = _co.emailForm; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 10).ngClassValid; var currVal_5 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_12 = !_co.emailForm.Valid; _ck(_v, 19, 0, currVal_12); }); }
export function View_ComposeEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-compose-email", [], null, null, null, View_ComposeEmailComponent_0, RenderType_ComposeEmailComponent)), i1.ɵdid(1, 114688, null, 0, i9.ComposeEmailComponent, [i2.FormBuilder, i10.UnityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComposeEmailComponentNgFactory = i1.ɵccf("app-compose-email", i9.ComposeEmailComponent, View_ComposeEmailComponent_Host_0, {}, { onDone: "onDone" }, []);
export { ComposeEmailComponentNgFactory as ComposeEmailComponentNgFactory };
