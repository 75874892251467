import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IScoresUpdate } from '../../utility/IScoresUpdate';
import { UnityService } from '../../modules/unity-service/unity.service';

@Component({
  templateUrl: './main.component.html',
  // selector: 'app-error-loc'
  // styleUrls: ['./error_oauth.component.scss']
})
export class MainComponent implements OnInit {

  @ViewChild('scoreElement') scoresElem: ElementRef;

  scores: IScoresUpdate;
  
  busy = true;

  constructor( private unityService: UnityService) {
  }

  ngOnInit() {
    // Extract parameters
    // this.globalParams.assign(this.route.snapshot.queryParamMap, this.route.snapshot.paramMap, this.route.paramMap);
  }

  onScoresUpdate(scores: IScoresUpdate) {
    // console.log(scores);
    this.scores = scores;
  }

  onScoresUpdatedByUser(scores: IScoresUpdate) {
    this.unityService.sendMessage(scores);
  }

  onStartRebuild() {
    this.busy = true;
  }

  onStopRebuild() {
    this.busy = false;
  }

  onShowScores() {
    this.scoresElem.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
}
