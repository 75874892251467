<div class="card mb-3">
  <div class="card-header bg-score d-flex align-items-center justify-content-between">
    <div>Score Foie - ABM</div>
    <div>{{ duvouxScore | number:'1.2-2' }}</div>
  </div>
  <div class="card-body">
    <form [formGroup]="duvouxForm.Group" novalidate>

      <tt-form-input [parameters]="duvouxForm.GetInputElement('taille')"></tt-form-input>
      <tt-form-input [parameters]="duvouxForm.GetInputElement('nodules')"></tt-form-input>

      <div class="row pt-3 pt-md-0">
        <div class="col-md-4">
          <tt-form-input [parameters]="duvouxForm.GetInputElement('afp')"></tt-form-input>
        </div>
        <div class="col-md-4">
          <tt-form-dropdown [parameters]="afpDropdown" (onSelect)="onAFPMesure($event)"></tt-form-dropdown>
        </div>
      </div>

      <tt-form-general-error [submitForm]="duvouxForm"></tt-form-general-error>

    </form>

    <p class="card-text font-italic">Duvoux C et al. Gastroenterol 2012</p>
  </div>
</div>