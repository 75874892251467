import { Component, OnInit, HostListener, ViewChild, ElementRef, NgZone, EventEmitter, Output } from '@angular/core';
import { UnityService } from '../../modules/unity-service/unity.service';
import { SubmitForm, ISubmitFormElement, ISubmitFormInputErrors } from '../../../../common/modules/utilities/submitform/submitform.class';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-compose-email',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss'],
})
export class ComposeEmailComponent implements OnInit {
  @Output() onDone = new EventEmitter();

  emailForm: SubmitForm;
  loading = false;
  enteredEmail = '';

  

  constructor(
    private fb: FormBuilder,
    private unityService: UnityService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.emailForm = new SubmitForm(
      this.fb,
      [
        <ISubmitFormElement>{
          name: 'email',
          value: '',
          validators: [Validators.required, Validators.email],
          type: 'email',
          title: 'Adresse email',
          autocomplete: 'email',
          placeholder: "Merci de renseigner l'adresse email du destinataire...",
          help: "L'adresse email du destinataire.",
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            },
            <ISubmitFormInputErrors>{
              code: 'email',
              message: "La valeur saisie n'est pas un email valable"
            }
          ]
        },
        <ISubmitFormElement>{
          name: 'objet',
          value: 'eRCPCHC',
          validators: [Validators.required],
          type: 'text',
          title: 'Objet',
          autocomplete: 'off',
          placeholder: '',
          help: "Merci de renseinger l'objet de votre email",
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ]
        },
        <ISubmitFormElement>{
          name: 'message',
          value: '',
          validators: [],
          type: 'text',
          title: 'Message',
          autocomplete: 'off',
          placeholder: '',
          help: 'Le message à envoyer avec les photos.',
          errors: [
          ]
        }
      ],
      // Submit callback
      data => {
        this.loading = true;
        // TODO: Do something
      },

      // Success callback
      () => {
        
      },

      // Fail callback
      err => {
        
      },

      // Changes callback
      null
    );
  }

  sendEmail() {

    const params = {
      type: 'send-email',
      Destination: this.emailForm.GetValue('email'),
      Subject: this.emailForm.GetValue('objet'),
      Message: this.emailForm.GetValue('message'),
    }

    this.unityService.sendMessage(params);
    this.onDone.emit();

  }

  cancelEmail() {
    const params = {
      type: 'cancel-email',
    }

    this.unityService.sendMessage(params);
    this.onDone.emit();
  }

}
