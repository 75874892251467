<div class="container">
  <div class="row">
    <div class="offset-md-4 col-md-4">
      <p>
        <img class="img-fluid" src="assets/common/images/404.svg">
      </p>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col d-flex justify-content-center align-item-center">
      <div class="tt-dog-bad d-flex justify-content-center align-item-center">
        <img src="assets/common/images/bulldog/BulldogFront.svg" width="80px">
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <h5 class="text-center">Not found</h5>
    </div>
  </div>
</div>