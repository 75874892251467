<div class="container-fluid header">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-between">
      <div class="title d-flex flex-column">
        <h1>eRCP</h1>
        <h2>Carcinome hepato cellulaire</h2>
      </div>      
    </div>

  </div>
</div>

<router-outlet></router-outlet>

<div class="container-fluid footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4">Copyright © SIGHT</div>
      <div class="col-md-4"></div>
      <div class="col-md-4"></div>
    </div>
  </div>
</div>