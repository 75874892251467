var Config = /** @class */ (function () {
    function Config(config, project) {
        this._config = config;
        this._project = project;
    }
    Object.defineProperty(Config.prototype, "Environment", {
        get: function () {
            return this._config;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "Project", {
        get: function () {
            return this._project;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "ApiFullPath", {
        /**
         * The fully qualified path for the API: https://domain/subpath/version
         */
        get: function () {
            return this.Environment.api.origin + this.Environment.api.subpath + this.Project.api.version;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath", {
        /// ---------- APP ------------ ///
        get: function () {
            return this.Environment.app.origin + this.Environment.app.subpath;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPathProject", {
        get: function () {
            return this.AppFullPath + this.Project.base;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppAccountFullPath", {
        get: function () {
            return this.AppFullPath + this.Project.app.account.url;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath_Login", {
        get: function () {
            return this.AppAccountFullPath + this.Project.app.account.login;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath_Login_Role", {
        get: function () {
            return this.AppFullPath_Login + '/' + this.Project.login.role;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath_Signup", {
        get: function () {
            return this.AppAccountFullPath + this.Project.app.account.signup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath_Signup_Role", {
        get: function () {
            return this.AppFullPath_Signup + '/' + this.Project.login.role;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath_AnonAuth", {
        get: function () {
            return this.AppAccountFullPath + this.Project.app.account.anonAuth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "AppFullPath_AnonAuth_Role", {
        get: function () {
            return this.AppFullPath_AnonAuth + '/' + this.Project.login.role;
        },
        enumerable: true,
        configurable: true
    });
    return Config;
}());
export { Config };
