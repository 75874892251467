import { Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { project } from '../environments/project';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not_found/not_found.component';
// Utilities
// Environment
// Views: Errors
// Views
// Services
var appRoutes = [
    /* { path: 'login/:role', component: LoginComponent },
    { path: 'login', redirectTo: '/login/consumer' },
    { path: 'signup/:role', component: SignupComponent },
    { path: 'signup', redirectTo: '/login/consumer' },
    { path: 'error_oauth/:role', component: ErrorOAuthComponent },
    { path: 'success_oauth/:role', component: SuccessOAuthComponent },
    { path: 'verify/:role/:token', component: VerifyComponent },
    { path: 'resend_verify/:role', component: ResendVerifyComponent },
    { path: 'send_reset_password/:role', component: SendResetPasswordComponent },
    { path: 'reset_password/:role/:token', component: ResetPasswordComponent },
    { path: 'anon_auth/:role', component: AnonAuthorizeComponent },*/
    { path: '', component: MainComponent },
    { path: '**', component: NotFoundComponent }
];
var ɵ0 = environment, ɵ1 = project;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
