import { Component, OnInit, HostListener, ViewChild, ElementRef, NgZone, EventEmitter, Output, Input, SimpleChanges, OnChanges, SimpleChange } from '@angular/core';
import { UnityService } from '../../modules/unity-service/unity.service';
import { SubmitForm, ISubmitFormElement, ISubmitFormInputErrors } from '../../../../common/modules/utilities/submitform/submitform.class';
import { FormBuilder, Validators } from '@angular/forms';
import { ITTFormDropdowParameters } from '../../../../common/modules/ngmodules/shared_editor/tt-form-dropdown/tt-form-dropdown.component';
import { ITTFormNameData } from '../../../../common/modules/ngmodules/shared_editor/shared.interfaces';
import { IScoresUpdate, IMeld, IChildPugh, IDuvoux } from '../../utility/IScoresUpdate';


enum AfpUnit {
  NgMl = 'ngMl',
  UIL = 'uil',
}

@Component({
  selector: 'app-duvoux',
  templateUrl: './duvoux.component.html',
  styleUrls: ['./duvoux.component.scss'],
})
export class DuvouxComponent implements OnInit, OnChanges {
  @Output() onScoresUpdated = new EventEmitter();
  @Input() scores: IScoresUpdate;

  duvouxForm: SubmitForm;
  loading = false;

  afpDropdown: ITTFormDropdowParameters;

  _duvoux: IDuvoux = {
    _AFP_ngmL: 0,
    _NoduleCount: 0,
    _SizeCm: 0
  };

  duvouxScore = 0;

  cpFinal = '';

  afpMesure: AfpUnit = AfpUnit.NgMl;

  constructor(
    private fb: FormBuilder,
    private unityService: UnityService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changeMap) {
    if ('scores' in changeMap) {
      const changes: SimpleChange = changeMap['scores'];
      if (changes.currentValue) {
        this._duvoux = (<IScoresUpdate>changes.currentValue).duvoux;

        // Reset the form
        this.createForm();

        // Recalculate
        this._recalculate(false);
        this.updateUI();
      }
    }
  }

  updateUI() {

    this.duvouxForm.SetValue('taille', +(this._duvoux._SizeCm).toFixed(2), false);
    this.duvouxForm.SetValue('nodules', +(this._duvoux._NoduleCount).toFixed(2), false);

    let afp = this._duvoux._AFP_ngmL;
    switch (this.afpMesure) {
      case AfpUnit.UIL:
        afp = +(afp / 1.126).toFixed(2);
        break;
    }

    this.duvouxForm.SetValue('afp', afp, false);
  }

  private _recalculate(fromUser: boolean) {

    this.duvouxScore = 0;

    if (this._duvoux._SizeCm > 3 && this._duvoux._SizeCm <= 6) {
      this.duvouxScore  += 1;
    } else if (this._duvoux._SizeCm > 6) {
      this.duvouxScore  += 4;
    }

    if (this._duvoux._NoduleCount >= 4) {
      this.duvouxScore  += 2;
    }

    if (this._duvoux._AFP_ngmL > 100 && this._duvoux._AFP_ngmL <= 1000) {
      this.duvouxScore  += 2;
    } else if (this._duvoux._AFP_ngmL > 1000) {
      this.duvouxScore  += 3;
    }

    if (fromUser) {
      this.onScoresUpdated.emit(this.scores);
    }
  }

  createForm() {
    this.duvouxForm = new SubmitForm(
      this.fb,
      [
        <ISubmitFormElement>{
          name: 'taille',
          value: 1,
          validators: [Validators.required],
          type: 'number',
          title: 'Taille (cm)',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        },
        <ISubmitFormElement>{
          name: 'nodules',
          value: 1,
          validators: [Validators.required],
          type: 'number',
          title: 'Nombre de nodules',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 1
        },
        <ISubmitFormElement>{
          name: 'afp',
          value: 1,
          validators: [Validators.required],
          type: 'number',
          title: 'αFP',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        }
      ],
      // Submit callback
      data => {
        this.loading = true;
        // TODO: Do something
      },

      // Success callback
      () => {
        
      },

      // Fail callback
      err => {
        
      },

      // Changes callback
      (key, value) => {
        switch (key) {
          case 'taille':
            this._duvoux._SizeCm = parseFloat(value);
            break;
          case 'nodules':
            this._duvoux._NoduleCount = parseInt(value, 1);
            break;
          case 'afp':
            this.setAfp(parseFloat(value));
            break;
        }
        this._recalculate(true);
      }
    );

    const mesureChoices = [
      <ITTFormNameData>{
        name: 'ng/ml',
        data: AfpUnit.NgMl
      },
      <ITTFormNameData>{
        name: 'UI/L',
        data: AfpUnit.UIL
      }
    ];


    this.afpDropdown = <ITTFormDropdowParameters>{
      choices: mesureChoices,
      selectedIndex : 0,
      input_title: 'Mesure',
      title: null,
      hasButton: false,
      help: ''
    };

  }

  private setAfp(value: number) {
    switch (this.afpMesure) {
      case AfpUnit.NgMl:
        this._duvoux._AFP_ngmL = value;
        break;
      case AfpUnit.UIL:
        this._duvoux._AFP_ngmL = +(value * 1.126).toFixed(2);
        break;
    }
  }

  onAFPMesure(mesure) {
    this.afpMesure = mesure.data;
    this.setAfp(this.duvouxForm.GetValue('afp'));
    this._recalculate(true);
    this.updateUI();
  }

}
