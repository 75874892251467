import { Component, Input  } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ISubmitFormElement } from '../../../utilities/submitform/submitform.class';


@Component({
  selector: 'tt-form-textarea',
  templateUrl: './tt-form-textarea.component.html'
})
export class TTFormTextAreaComponent {
  @Input() parameters : ISubmitFormElement ;

  /*
  ngOnInit() {
    console.log(this.parameters);
  }*/

}

// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
// 