
const server = 'https://demo.tallytoo.com';

// APP FRONTEND
export const APP_DOMAIN = server;
export const APP_SUBPATH = '';

// API BACKEND
export const API_DOMAIN = server;
export const API_SUBPATH = '/api';

// SOCKET CONNECTION
export const API_SOCKET_DOMAIN = server;
export const API_SOCKET_SUBPATH = '';

