
<div class="d-flex justify-content-between align-items-center collapsible" (click)="toggleCollapse()">
  <h2 class="mb-0">Mentions légales</h2>  
  <span class="indicator" *ngIf="isCollapsed">&#x002B;</span>
  <span class="indicator" *ngIf="!isCollapsed">&#x2212;</span>
</div>


<div #collapseArea class="legal collapse-area">
  <p class="mt-3">L'application CHC Planner, éditée par SIGHT avec le soutien institutionnel de Bayer, est destinée aux professionnels de santé. CHC Planner a pour objectif l'éducation thérapeutique des patients présentant un carcinome hépatocellulaire. Les images créées, affichées et exportées à partir de CHC Planner sont uniquement des schémas. Ces schémas ne sont pas réalisés à partir de l'imagerie des patients et ne peuvent donc pas être utilisés pour prendre des décisions médicales.</p>

  <h5>Editeur de l’application</h5>

  <ul>
    <li>SIGHT</li>
    <li>16, rue Abel</li>
    <li>75012, Paris</li>
    <li>France</li>
    <li>Téléphone : +33 (0)1 43 46 53 72</li>
  </ul>

  <h5>Directeur de la publication</h5>
  <p>Karen Vernimmen</p>

  <h5>Responsable de la  rédaction</h5>
  <p>Eric Vibert</p>

  <h5>Conditions Générales d'Utilisation</h5>

  <p>eRCP CHC est une application pour web destinée aux professionnels de santé. Elle permet d’informer le patient sur les traitements non chirurgicaux du cancer du foie.</p>
  
  <p>L’accès à cette application et son utilisation sont soumis aux conditions suivantes. A défaut d’acceptation des présentes conditions générales d’utilisation, nous vous demandons de vous abstenir d’utiliser cette application.</p>
  
  <p>Cette application a été développée et est administrée par la société SIGHT avec le soutien institutionnel de Bayer Healthcare SAS. SIGHT est susceptible, à tout moment, d’interrompre ou d’apporter des modifications à cette application ; il en va de même en ce qui concerne ses Conditions Générales d’Utilisation. SIGHT peut proposer de nouvelles versions et mises à jour de cette application en fonction de l’évolution de ses services tout comme elle peut, à tout moment et pour quelque raison que ce soit, retirer l’application des plateformes de téléchargement. Par conséquent, nous vous invitons à lire de nouveau attentivement les Conditions générales d’utilisation de cette application et à prendre note des changements ou modifications ayant été éventuellement apportées par nos soins.</p>
  
  <h5>Responsabilité limitée</h5>

  <p>Ni SIGHT ni les tiers impliqués dans la conception, la réalisation ou le transfert de cette application ne peuvent être tenus responsables des dommages matériels ou corporels résultant de l'accès à cette application ou de l'impossibilité d'y accéder, ou de l'utilisation de cette application ou de l'impossibilité de l'utiliser, ou résultant d'informations données dans cette application. Les images créées, affichées et exportées à partir des eRCP sont des schémas. Ces schémas ne sont pas réalisés à partir de l'imagerie des patients et ne peuvent donc pas être utilisés pour prendre des décisions médicales.</p>
  
  <h5>Confidentialité des données personnelles</h5>

  <p>SIGHT ne collecte aucune information personnelle sur les utilisateurs de cette application. Cette application, après téléchargement, ne nécessite aucune connexion à un serveur ou site internet et ne transmet aucune donnée provenant de votre appareil.</p>
  
  <h5>Utilisateurs internationaux</h5>

  <p>Le contrôle, le fonctionnement et les mises à jour de cette application sont effectués par SIGHT en France. Cette application est réservée exclusivement aux utilisateurs situés en France. SIGHT ne garantit nullement que les informations présentées dans cette application demeurent exactes en dehors de la France. Si vous accédez à cette application depuis un endroit situé en dehors de la France ou si vous téléchargez le contenu à partir de ce même endroit, nous vous rappelons qu'il vous appartient, et ce sous votre propre et entière responsabilité, de vous conformer à la législation en vigueur dans votre juridiction.</p>

  <h5>Loi applicable</h5>

  <p>L’application et son contenu sont régis par le droit français et tout litige éventuel s’y rapportant sera soumis à la compétence des tribunaux français.</p>

  <p>Date d’établissement : février 2019</p>

</div>