/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../unity-app/unity-app.component.ngfactory";
import * as i2 from "../unity-app/unity-app.component";
import * as i3 from "../../modules/unity-service/unity.service";
import * as i4 from "../meld/meld.component.ngfactory";
import * as i5 from "../meld/meld.component";
import * as i6 from "@angular/forms";
import * as i7 from "../childpugh/childpugh.component.ngfactory";
import * as i8 from "../childpugh/childpugh.component";
import * as i9 from "../duvoux/duvoux.component.ngfactory";
import * as i10 from "../duvoux/duvoux.component";
import * as i11 from "../../../../common/modules/components/ttwait/ttwait.component.ngfactory";
import * as i12 from "../../../../common/modules/components/ttwait/ttwait.component";
import * as i13 from "../legal/legal.component.ngfactory";
import * as i14 from "../legal/legal.component";
import * as i15 from "./main.component";
var styles_MainComponent = [];
var RenderType_MainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
export function View_MainComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { scoresElem: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "container bg-blue-bayer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col p-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-unity", [], null, [[null, "onScoresUpdate"], [null, "onStartRebuild"], [null, "onStopRebuild"], [null, "onShowScores"], ["window", "resize"], ["window", "message"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:message" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onReceivedMessage($event) !== false);
        ad = (pd_1 && ad);
    } if (("onScoresUpdate" === en)) {
        var pd_2 = (_co.onScoresUpdate($event) !== false);
        ad = (pd_2 && ad);
    } if (("onStartRebuild" === en)) {
        var pd_3 = (_co.onStartRebuild() !== false);
        ad = (pd_3 && ad);
    } if (("onStopRebuild" === en)) {
        var pd_4 = (_co.onStopRebuild() !== false);
        ad = (pd_4 && ad);
    } if (("onShowScores" === en)) {
        var pd_5 = (_co.onShowScores() !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_UnityAppComponent_0, i1.RenderType_UnityAppComponent)), i0.ɵdid(5, 114688, null, 0, i2.UnityAppComponent, [i3.UnityService, i0.NgZone], null, { onScoresUpdate: "onScoresUpdate", onStartRebuild: "onStartRebuild", onStopRebuild: "onStopRebuild", onShowScores: "onShowScores" }), (_l()(), i0.ɵeld(6, 0, null, null, 13, "div", [["class", "container-fluid bg-white pt-3 pb-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, [[1, 0], ["scoreElement", 1]], null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 10, "div", [["class", "col pl-0 pr-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Scores"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-meld", [["class", "mb-3"]], null, [[null, "onScoresUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onScoresUpdated" === en)) {
        var pd_0 = (_co.onScoresUpdatedByUser($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MeldComponent_0, i4.RenderType_MeldComponent)), i0.ɵdid(13, 638976, null, 0, i5.MeldComponent, [i6.FormBuilder, i3.UnityService], { scores: [0, "scores"] }, { onScoresUpdated: "onScoresUpdated" }), (_l()(), i0.ɵeld(14, 0, null, null, 1, "app-childpugh", [["class", "mb-3"]], null, [[null, "onScoresUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onScoresUpdated" === en)) {
        var pd_0 = (_co.onScoresUpdatedByUser($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ChildPughComponent_0, i7.RenderType_ChildPughComponent)), i0.ɵdid(15, 638976, null, 0, i8.ChildPughComponent, [i6.FormBuilder, i3.UnityService], { scores: [0, "scores"] }, { onScoresUpdated: "onScoresUpdated" }), (_l()(), i0.ɵeld(16, 0, null, null, 1, "app-duvoux", [["class", "mb-3"]], null, [[null, "onScoresUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onScoresUpdated" === en)) {
        var pd_0 = (_co.onScoresUpdatedByUser($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_DuvouxComponent_0, i9.RenderType_DuvouxComponent)), i0.ɵdid(17, 638976, null, 0, i10.DuvouxComponent, [i6.FormBuilder, i3.UnityService], { scores: [0, "scores"] }, { onScoresUpdated: "onScoresUpdated" }), (_l()(), i0.ɵeld(18, 0, null, null, 1, "tt-wait", [], null, null, null, i11.View_WaitComponent_0, i11.RenderType_WaitComponent)), i0.ɵdid(19, 573440, null, 0, i12.WaitComponent, [], { show: [0, "show"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 5, "div", [["class", "container-fluid bg-darker-bayer fg-white  pt-3 pb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, [["legalElement", 1]], null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 1, "app-legal", [], null, null, null, i13.View_LegalComponent_0, i13.RenderType_LegalComponent)), i0.ɵdid(25, 49152, null, 0, i14.LegalComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = _co.scores; _ck(_v, 13, 0, currVal_0); var currVal_1 = _co.scores; _ck(_v, 15, 0, currVal_1); var currVal_2 = _co.scores; _ck(_v, 17, 0, currVal_2); var currVal_3 = _co.busy; _ck(_v, 19, 0, currVal_3); }, null); }
export function View_MainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MainComponent_0, RenderType_MainComponent)), i0.ɵdid(1, 114688, null, 0, i15.MainComponent, [i3.UnityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainComponentNgFactory = i0.ɵccf("ng-component", i15.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
