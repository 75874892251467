<div class="row emailComposer">
  <div class="col">
    <div class="card">
      <div class="card-body">
    
        <h3 class="mb-4">Envoyer un email</h3>

        <form [formGroup]="emailForm.Group" novalidate>

          <tt-form-input [parameters]="emailForm.GetInputElement('email')"></tt-form-input>
          <tt-form-input [parameters]="emailForm.GetInputElement('objet')"></tt-form-input>
          <tt-form-textarea [parameters]="emailForm.GetInputElement('message')"></tt-form-textarea>
    
          <tt-form-general-error [submitForm]="emailForm"></tt-form-general-error>
    
        </form>

        <button type="button" id="signup" class="btn btn-success tt-btn-large-round tt-btn-pointer" (click)="sendEmail()"
          [disabled]="!emailForm.Valid">
          Envoyez !
        </button>

        <button type="button" id="signup" class="btn btn-danger tt-btn-large-round tt-btn-pointer ml-3" (click)="cancelEmail()">
          Annuler
        </button>
      </div>
  

    </div>
  </div>
</div>