import { EventEmitter, OnChanges, OnInit, SimpleChange } from '@angular/core';
var TTFormDropdownComponent = /** @class */ (function () {
    function TTFormDropdownComponent() {
        this.onSelect = new EventEmitter();
        this.onClick = new EventEmitter();
        this.index = 0;
    }
    Object.defineProperty(TTFormDropdownComponent.prototype, "selected", {
        get: function () {
            return this.parameters.choices[this.index].name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TTFormDropdownComponent.prototype, "hasData", {
        get: function () {
            if (this.parameters) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    TTFormDropdownComponent.prototype.ngOnInit = function () {
        if (this.parameters) {
            this.index = this.parameters.selectedIndex;
        }
    };
    TTFormDropdownComponent.prototype.ngOnChanges = function (changeMap) {
        if ('parameters' in changeMap) {
            var change = changeMap.parameters;
            if (change.currentValue) {
                this.index = this.parameters.selectedIndex;
            }
        }
    };
    TTFormDropdownComponent.prototype.onSelected = function (nameValue) {
        this.index = this.parameters.choices.indexOf(nameValue);
        this.onSelect.emit(nameValue);
    };
    TTFormDropdownComponent.prototype.onClicked = function () {
        this.onClick.emit(this.parameters.choices[this.index]);
    };
    return TTFormDropdownComponent;
}());
export { TTFormDropdownComponent };
