/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./not_found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "offset-md-4 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["class", "img-fluid"], ["src", "assets/common/images/404.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "col d-flex justify-content-center align-item-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "tt-dog-bad d-flex justify-content-center align-item-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["src", "assets/common/images/bulldog/BulldogFront.svg"], ["width", "80px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "h5", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Not found"]))], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i1.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i0.ɵccf("ng-component", i1.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
