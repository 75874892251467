import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  templateUrl: './legal.component.html',
  selector: 'app-legal',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent {
  @ViewChild('collapseArea') areaRef: ElementRef;
  isCollapsed = true;


  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;

    if (this.isCollapsed) {
      this.areaRef.nativeElement.style.maxHeight = null;
    } else {
      this.areaRef.nativeElement.style.maxHeight = this.areaRef.nativeElement.scrollHeight + "px";
    }
  }
}
