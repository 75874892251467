<div class="form-group" [formGroup]="parameters._submitForm.Group">
    <div class="input-group input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text">{{parameters.title}}</span>
      </div>
      <textarea class="form-control" formControlName="{{parameters.name}}" placeholder="{{parameters.placeholder}}"></textarea>
    </div>
    <div class="invalid-feedback" style="display: block;" [hidden]="parameters._submitForm.FeedbackHidden(parameters.name)">
      <div *ngFor="let error of parameters.errors" [hidden]="parameters._submitForm.ErrorHidden(parameters.name,error.code)">{{error.message}}</div>
      <div [hidden]="parameters._submitForm.ErrorHidden(parameters.name,'server')">{{parameters._submitForm.Error}}</div>
      <div [hidden]="parameters._submitForm.ErrorHidden(parameters.name,'unknown')">An unknown error occurred.</div>
    </div>
    <small *ngIf="parameters.help != null" class="form-text text-muted">{{parameters.help}}</small>
  </div>