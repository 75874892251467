import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { Config } from '../../common/modules/services/config.service';
import { environment } from '../environments/environment';
import { project } from '../environments/project';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not_found/not_found.component';
import { UnityService } from './modules/unity-service/unity.service';
import { UnityAppComponent } from './components/unity-app/unity-app.component';
import { CommonModule } from '@angular/common';
import { TTFormInputComponent } from '../../common/modules/ngmodules/shared_editor/tt-form-input/tt-form-input.component';
import { TTFormGeneralErrorComponent } from '../../common/modules/ngmodules/shared_editor/tt-form-general-error/tt-form-general-error.component';
import { ComposeEmailComponent } from './components/compose_email/compose.component';
import { TTFormTextAreaComponent } from '../../common/modules/ngmodules/shared_editor/tt-form-textarea/tt-form-textarea.component';
import { MeldComponent } from './components/meld/meld.component';
import { TTFormDropdownComponent } from '../../common/modules/ngmodules/shared_editor/tt-form-dropdown/tt-form-dropdown.component';
import { WaitComponent } from '../../common/modules/components/ttwait/ttwait.component';
import { ChildPughComponent } from './components/childpugh/childpugh.component';
import { DuvouxComponent } from './components/duvoux/duvoux.component';
import { LegalComponent } from './components/legal/legal.component';

// Utilities
// Environment
// Views: Errors
// Views
// Services


const appRoutes: Routes  = [
  /* { path: 'login/:role', component: LoginComponent },
  { path: 'login', redirectTo: '/login/consumer' },
  { path: 'signup/:role', component: SignupComponent },
  { path: 'signup', redirectTo: '/login/consumer' },
  { path: 'error_oauth/:role', component: ErrorOAuthComponent },
  { path: 'success_oauth/:role', component: SuccessOAuthComponent },
  { path: 'verify/:role/:token', component: VerifyComponent },
  { path: 'resend_verify/:role', component: ResendVerifyComponent },
  { path: 'send_reset_password/:role', component: SendResetPasswordComponent },
  { path: 'reset_password/:role/:token', component: ResetPasswordComponent },
  { path: 'anon_auth/:role', component: AnonAuthorizeComponent },*/
  { path: '', component: MainComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    // Utilities
    UnityAppComponent,
    // WaitComponent,
    // Views
    MainComponent,
    NotFoundComponent,
    ComposeEmailComponent,

    MeldComponent,
    ChildPughComponent,
    DuvouxComponent,

    LegalComponent,

    TTFormInputComponent,
    TTFormTextAreaComponent,
    TTFormDropdownComponent,
    TTFormGeneralErrorComponent,

    WaitComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(
      appRoutes // ,
      // { enableTracing: true } // <-- debugging
    )
  ],
  providers: [
    { provide: 'CONFIG', useValue: environment },
    { provide: 'PROJECT', useValue: project },
    Config,
    UnityService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
