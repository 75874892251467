/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tt-form-general-error.component";
var styles_TTFormGeneralErrorComponent = [];
var RenderType_TTFormGeneralErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TTFormGeneralErrorComponent, data: {} });
export { RenderType_TTFormGeneralErrorComponent as RenderType_TTFormGeneralErrorComponent };
export function View_TTFormGeneralErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "form-group invalid-feedback"], ["style", "display: block;"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Erreur inconnue"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.submitForm.GeneralErrorHidden(); _ck(_v, 0, 0, currVal_0); var currVal_1 = !_co.submitForm.GeneralErrorHasMessage(); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.submitForm.Error; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.submitForm.GeneralErrorHasMessage(); _ck(_v, 3, 0, currVal_3); }); }
export function View_TTFormGeneralErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tt-form-general-error", [], null, null, null, View_TTFormGeneralErrorComponent_0, RenderType_TTFormGeneralErrorComponent)), i0.ɵdid(1, 49152, null, 0, i1.TTFormGeneralErrorComponent, [], null, null)], null, null); }
var TTFormGeneralErrorComponentNgFactory = i0.ɵccf("tt-form-general-error", i1.TTFormGeneralErrorComponent, View_TTFormGeneralErrorComponent_Host_0, { submitForm: "submitForm" }, {}, []);
export { TTFormGeneralErrorComponentNgFactory as TTFormGeneralErrorComponentNgFactory };
