<div class="card mb-3">
  <div class="card-header bg-score d-flex align-items-center justify-content-between">
      <div>Child-Pugh</div>
      <div>{{ cpFinal }}</div>
  </div>
  <div class="card-body">
    <form [formGroup]="childPughForm.Group" novalidate>
      <tt-form-input [parameters]="childPughForm.GetInputElement('tp')"></tt-form-input>

      <div class="row pt-3 pt-md-0">
        <div class="col-md-4">
          <tt-form-input [parameters]="childPughForm.GetInputElement('bilirubin')"></tt-form-input>
        </div>
        <div class="col-md-4">
          <tt-form-dropdown [parameters]="bilirubinDropdown" (onSelect)="onBilirubinMesure($event)"></tt-form-dropdown>
        </div>
      </div>

      <tt-form-input [parameters]="childPughForm.GetInputElement('albumine')"></tt-form-input>

      <tt-form-dropdown [parameters]="asciteDropdown" (onSelect)="onAscite($event)"></tt-form-dropdown>
      <tt-form-dropdown [parameters]="encaphDropdown" (onSelect)="onEnceph($event)"></tt-form-dropdown>

      <tt-form-general-error [submitForm]="childPughForm"></tt-form-general-error>

    </form>

    <p class="card-text font-italic">Child CG et al. Major Probl Clin Surg 1964</p>
  </div>
</div>