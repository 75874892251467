import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  /*host: {
    '(window:beforeunload)': 'onWindowClose($event)'
  }*/
})
export class AppComponent {
/*
  headerText: string;

  ready = false;
  errLoc = false;

  constructor(
    private globalParams: ParamService,
    private router: Router,
    private localisation: Localisation,
    private config: Config
  ) {
    this.globalParams.CurrentRoleObservable.subscribe(
      (role: string) => {
        this.setTitle(role);
      }
    );

    // Init application-wide localization
    this.localisation.initialize(this.config.Project.system)
    .then(
      () => {
        this.ready = true;
        // Do this in backgroun
        this.setupLanguageList();
      }
    )
    .catch(
      () => {
        this.errLoc = true;
      }
    );

  }

  private setupLanguageList() {

    this.localisation.listLanguages()
    .then(
      (localisations: ILocalisation[]) => {
        this.languages = localisations;
      }
    )
    .catch(
      (err) => {
        console.warn(err.message);
      }
    );

  }

  public setLanguage(langCode: string) {
    this.localisation.changeLanguage(langCode);
  }

  public get LanguageName(): string {
    return this.localisation.localise('name');
  }

  public get RoleName(): string {
    return this.localisation.localise('role.' + this.Role);
  }

  public get IsPopup(): boolean {
    return this.globalParams.IsPopup;
  }

  public get Role(): string  {
    return this.globalParams.Role;
  }

  setTitle(role: string) {
    this.headerText = this.localisation.localise('role.' + role);
    if (role === 'consumer') {
      this.headerText = null;
    }
  }

  changeRole(role: string) {
    this.globalParams.setSuccessURL(role);
    this.router.navigate(['login', role], {queryParams: this.globalParams.getQueryParams()});
  }
*/

}
