import { IConfig } from '../../common/modules/interfaces/config/IConfig';
import { API_DOMAIN, API_SUBPATH, API_SOCKET_DOMAIN, API_SOCKET_SUBPATH, APP_DOMAIN, APP_SUBPATH } from '../../common/environment/config.prod';

export const environment: IConfig = {
  api: {
    origin: API_DOMAIN,
    subpath: API_SUBPATH,
    socket: {
      socket_origin: API_SOCKET_DOMAIN,
      socket_subpath: API_SOCKET_SUBPATH
    }
  },
  app: {
    origin: APP_DOMAIN,
    subpath: APP_SUBPATH
  },

  production: true
};
