import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';

import { UnityLoader } from '../UnityLoader.js';

declare let window: any;


@Injectable()
export class UnityService implements OnInit {
  private gameInstance: any;
  private onProgressRef: any;

  ngOnInit() {

  }

  public load(componentId: string, progressCallback: any) {
    window.UnityLoader = UnityLoader;
    this.onProgressRef = progressCallback;
    console.log(this.onProgressRef);
    this.gameInstance = UnityLoader.instantiate(componentId, '../../../assets/eRCPCHC.json', {onProgress: (gameInstance, progress) => { this.onProgress(gameInstance, progress); }});
  }

  public sendMessage(message: any) {
    this.gameInstance.SendMessage('Interface', 'PostMessage', JSON.stringify(message));
  }

  public onProgress(gameInstance, progress) {
    // console.log('Progress: ' + progress);
    this.onProgressRef (progress);
  }

}
