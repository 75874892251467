import { IProjectAppConfig } from '../modules/interfaces/config/IProject';

export const API_VERSION = '/v1';
export const API_SOCKET_TIMEOUT = 30000;


export const APP_PROJECT_CONFIG: IProjectAppConfig = {
  account: {
    url: '/account',
    login: '/login',
    signup: '/signup',
    anonAuth: '/anon_auth'
  },
  queryParams: {
    success: {
      name: 'successURL',
      key: 'tt-success-url',
      default: '/'
    },
    context: {
      name: 'context',
      key: 'tt-login-context',
      default: {}
    },
  }
};
