import { Component, OnInit, HostListener, ViewChild, ElementRef, NgZone, EventEmitter, Output, Input, SimpleChanges, OnChanges, SimpleChange } from '@angular/core';
import { UnityService } from '../../modules/unity-service/unity.service';
import { SubmitForm, ISubmitFormElement, ISubmitFormInputErrors } from '../../../../common/modules/utilities/submitform/submitform.class';
import { FormBuilder, Validators } from '@angular/forms';
import { ITTFormDropdowParameters } from '../../../../common/modules/ngmodules/shared_editor/tt-form-dropdown/tt-form-dropdown.component';
import { ITTFormNameData } from '../../../../common/modules/ngmodules/shared_editor/shared.interfaces';
import { IScoresUpdate, IMeld, IChildPugh } from '../../utility/IScoresUpdate';
import { MesureUnit } from '../meld/meld.component';


enum ExtentType {
  Absent = 0,
  Moderate = 1,
  Severe = 2
}

enum ChildType {
  ChildA = 'A',
  ChildB = 'B',
  ChildC = 'C'
}

@Component({
  selector: 'app-childpugh',
  templateUrl: './childpugh.component.html',
  styleUrls: ['./childpugh.component.scss'],
})
export class ChildPughComponent implements OnInit, OnChanges {
  @Output() onScoresUpdated = new EventEmitter();
  @Input() scores: IScoresUpdate;

  childPughForm: SubmitForm;
  loading = false;

  bilirubinDropdown: ITTFormDropdowParameters;
  asciteDropdown: ITTFormDropdowParameters;
  encaphDropdown: ITTFormDropdowParameters;

  _childpugh: IChildPugh = {
    _Albumin_gL: 0,
    _TP: 0,
    _Ascite: ExtentType.Absent,
    _Bilirubin_molL: 0,
    _Encaphalopathy: ExtentType.Absent
  };
  cpScore = 0;
  cpChild: ChildType = ChildType.ChildA;

  cpFinal = '';

  bilirubinMesure: MesureUnit = MesureUnit.MicroMolPerLitre;

  constructor(
    private fb: FormBuilder,
    private unityService: UnityService
  ) {
    console.log(this._childpugh);
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changeMap) {
    if ('scores' in changeMap) {
      const changes: SimpleChange = changeMap['scores'];
      if (changes.currentValue) {
        this._childpugh = (<IScoresUpdate>changes.currentValue).childpugh;

        // Reset the form
        this.createForm();

        // Recalculate
        this._recalculate(false);
        this.updateUI();
      }
    }
  }

  updateUI() {

    this.childPughForm.SetValue('tp', +(this._childpugh._TP).toFixed(2), false);

    let bilimgDL = this._childpugh._Bilirubin_molL;
    switch (this.bilirubinMesure) {
      case MesureUnit.MiligramPerDeciLitre:
        bilimgDL = +(bilimgDL / 17.1).toFixed(2);
        break;
    }

    this.childPughForm.SetValue('bilirubin', +(bilimgDL).toFixed(2), false);

    this.childPughForm.SetValue('albumine', +(this._childpugh._Albumin_gL).toFixed(2), false);
    
    this._setDropdowns();
  }

  private _recalculate(fromUser: boolean) {

    this.cpScore = 0;

    if (this._childpugh._TP > 50) {
      this.cpScore += 1;
    } else if (this._childpugh._TP >= 40 && this._childpugh._TP <= 50) {
      this.cpScore += 2 ;
    } else if (this._childpugh._TP < 40) {
      this.cpScore += 3;
    }

    if (this._childpugh._Albumin_gL > 35) {
      this.cpScore += 1;
    } else if (this._childpugh._Albumin_gL >= 28 && this._childpugh._Albumin_gL <= 35) {
      this.cpScore += 2;
    } else if (this._childpugh._Albumin_gL < 28) {
      this.cpScore += 3;
    }

    if (this._childpugh._Bilirubin_molL < 35) {
      this.cpScore += 1;
    } else if (this._childpugh._Bilirubin_molL >= 35 && this._childpugh._Bilirubin_molL <= 50) {
      this.cpScore += 2;
    } else if (this._childpugh._Bilirubin_molL > 50) {
      this.cpScore += 3;
    }

    switch (this._childpugh._Ascite) {
      case ExtentType.Absent: this.cpScore += 1; break;
      case ExtentType.Moderate: this.cpScore += 2; break;
      case ExtentType.Severe: this.cpScore += 3; break;
    }

    switch (this._childpugh._Encaphalopathy) {
      case ExtentType.Absent: this.cpScore += 1; break;
      case ExtentType.Moderate: this.cpScore += 2; break;
      case ExtentType.Severe: this.cpScore += 3; break;
    }

    if (this.cpScore <= 6) {
      this.cpChild = ChildType.ChildA;
    } else if (this.cpScore >= 7 && this.cpScore <= 9) {
      this.cpChild = ChildType.ChildB;
    } else if (this.cpScore >= 10) {
      this.cpChild = ChildType.ChildC;
    }

    this.cpFinal = this.cpChild + Math.round(this.cpScore);

    if (fromUser) {
      this.onScoresUpdated.emit(this.scores);
    }
  }

  createForm() {
    this.childPughForm = new SubmitForm(
      this.fb,
      [
        <ISubmitFormElement>{
          name: 'tp',
          value: 1,
          validators: [Validators.required],
          type: 'number',
          title: 'TP',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        },
        <ISubmitFormElement>{
          name: 'bilirubin',
          value: 1,
          validators: [Validators.required],
          type: 'number',
          title: 'Bilirubin',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        },
        <ISubmitFormElement>{
          name: 'albumine',
          value: '',
          validators: [Validators.required],
          type: 'number',
          title: 'Albumine (g/L)',
          autocomplete: 'off',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Ce champ est obligatoire'
            }
          ],
          step: 0.1
        }
      ],
      // Submit callback
      data => {
        this.loading = true;
        // TODO: Do something
      },

      // Success callback
      () => {
        
      },

      // Fail callback
      err => {
        
      },

      // Changes callback
      (key, value) => {
        console.log(key + " -> " + value);
        switch (key) {
          case 'tp': 
            this._childpugh._TP = parseFloat(value);
            break;
          case 'bilirubin':
            this.setBilirubin(parseFloat(value));
            break;
          case 'albumine':
            this._childpugh._Albumin_gL = parseFloat(value);
            break;
        }
        this._recalculate(true);
      }
    );

    const mesureChoices = [
      <ITTFormNameData>{
        name: 'µmol/L',
        data: MesureUnit.MicroMolPerLitre
      },
      <ITTFormNameData>{
        name: 'mg/dL',
        data: MesureUnit.MiligramPerDeciLitre
      }
    ];


    this.bilirubinDropdown = <ITTFormDropdowParameters>{
      choices: mesureChoices,
      selectedIndex : 0,
      input_title: 'Mesure',
      title: null,
      hasButton: false,
      help: ''
    };

    this._setDropdowns();
  }

  private _setDropdowns() {
    const presenceChoices = [
      <ITTFormNameData>{
        name: 'Absente',
        data: ExtentType.Absent
      },
      <ITTFormNameData>{
        name: 'Modérée',
        data: ExtentType.Moderate
      },
      <ITTFormNameData>{
        name: 'Sévère',
        data: ExtentType.Severe
      }
    ];

    this.asciteDropdown = <ITTFormDropdowParameters>{
      choices: presenceChoices,
      selectedIndex : this._childpugh._Ascite,
      input_title: 'Ascite',
      title: null,
      hasButton: false,
      help: ''
    };

    this.encaphDropdown = <ITTFormDropdowParameters>{
      choices: presenceChoices,
      selectedIndex : this._childpugh._Encaphalopathy,
      input_title: 'Encéphalopathie hepatique',
      title: null,
      hasButton: false,
      help: ''
    };
  }

  private setBilirubin(value: number) {
    switch (this.bilirubinMesure) {
      case MesureUnit.MicroMolPerLitre:
        this._childpugh._Bilirubin_molL = value;
        break;
      case MesureUnit.MiligramPerDeciLitre:
        this._childpugh._Bilirubin_molL = +(value * 17.1).toFixed(2);
        break;
    }
  }

  onBilirubinMesure(mesure) {
    this.bilirubinMesure = mesure.data;
    this.setBilirubin(this.childPughForm.GetValue('bilirubin'));
    this._recalculate(true);
    this.updateUI();
  }

  onAscite(mesure) {
    this._childpugh._Ascite = mesure.data;
    this._recalculate(true);
    this.updateUI();
  }

  onEnceph(mesure) {
    this._childpugh._Encaphalopathy = mesure.data;
    this._recalculate(true);
    this.updateUI();
  }

}
