import { Component } from '@angular/core';

@Component({
  templateUrl: './not_found.component.html'
})
export class NotFoundComponent {

  constructor(
  ) {
  }

}
