/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unity-app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../compose_email/compose.component.ngfactory";
import * as i3 from "../compose_email/compose.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../modules/unity-service/unity.service";
import * as i6 from "@angular/common";
import * as i7 from "./unity-app.component";
var styles_UnityAppComponent = [i0.styles];
var RenderType_UnityAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnityAppComponent, data: {} });
export { RenderType_UnityAppComponent as RenderType_UnityAppComponent };
function View_UnityAppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "email-full embed-responsive-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-compose-email", [], null, [[null, "onDone"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDone" === en)) {
        var pd_0 = (_co.closeEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ComposeEmailComponent_0, i2.RenderType_ComposeEmailComponent)), i1.ɵdid(3, 114688, null, 0, i3.ComposeEmailComponent, [i4.FormBuilder, i5.UnityService], null, { onDone: "onDone" })], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_UnityAppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { game: 0 }), i1.ɵqud(402653184, 2, { splash: 0 }), i1.ɵqud(402653184, 3, { progress: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "embed-responsive embed-responsive-16by9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["game", 1]], null, 0, "div", [["class", "embed-responsive-item"], ["id", "gamecontainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[2, 0], ["splash", 1]], null, 4, "div", [["class", "loader-full embed-responsive-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Chargement..."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "loader-back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[3, 0], ["progress", 1]], null, 0, "div", [["class", "loader-fill"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnityAppComponent_1)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.composeEmail; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_UnityAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unity", [], null, [["window", "resize"], ["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:message" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReceivedMessage($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_UnityAppComponent_0, RenderType_UnityAppComponent)), i1.ɵdid(1, 114688, null, 0, i7.UnityAppComponent, [i5.UnityService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnityAppComponentNgFactory = i1.ɵccf("app-unity", i7.UnityAppComponent, View_UnityAppComponent_Host_0, {}, { onScoresUpdate: "onScoresUpdate", onStartRebuild: "onStartRebuild", onStopRebuild: "onStopRebuild", onShowScores: "onShowScores" }, []);
export { UnityAppComponentNgFactory as UnityAppComponentNgFactory };
